#mobilemenu {
    background-color: $darkblue;
    color:$lightblue;

    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height:100%;

    z-index: 100;

    box-sizing: border-box;
    /*
    padding-top:rem-calc(120);
    padding-left:var(--lrmargin);
    padding-right:var(--lrmargin);
    padding-bottom:rem-calc(20);
    */

    .backlink, ul a {
        padding:rem-calc(7) 0;
    }
    li > a {
        display:flex;
        gap:0.25em;
        justify-content: flex-end;
        align-items: baseline;
    }
    /*
    li.hassubmenu > a {
        display:flex;
        justify-content: space-between;
        align-items: center;
    }
    */
    .backlink {
        display:flex;
        gap:0.5em;
        justify-content: flex-end;
        align-items: baseline;
    }
    .icon-menuarrowright,
    .icon-menuarrowleft {
        height:rem-calc(18);
    }

    .ctrmenu {
        position: absolute;
        left:var(--lrmargin);
        right:var(--lrmargin);
        bottom:rem-calc(120);
    }

    .ctrlogo {
        width:var(--headerlogowidth);
        img {
            @include w100bl;
        }
        margin-left:var(--lrmargin);
        margin-top:var(--headerpaddingtop);
    }
}

#mobilemenu {
    transition:opacity .4s;
    .ctrmenu {
        transition: transform .4s;
    }
}
#mobilemenu:not(.visible) { 
    pointer-events: none;
    opacity:0;
    .ctrmenu {
        transform: translateY(50px);
    }
}

/*
#hamburger-mobilemenu {
    @include breakpoint(large) {
        display: none;
    }

    position:fixed;
    right:var(--lrmargin);
    top:rem-calc(25);
    z-index: 200;

    .mobilemenuvisible & {
        --hbnormal:#fff;
        --hbhover:#fff;
    }
}
*/

//typo
#mobilemenu {
    @include font-size-pt(30, 30);
    font-weight: 400;
    line-height: auto;

    .backlink {
        color:$cyan;
        margin-bottom: 2em;
    }
    .active > a {
        color:$cyan;
    }
}