//positioning
.bl-aktuelltext {
    @include grid12lr;
    @include breakpoint(medium) {
        .ctrtitle {
            grid-column: 4 / span 6;
        }
        .ctrtext {
            grid-column: 4 / span 4;
        }
    }
    @include breakpoint(small down) {
        .ctrtitle {
            grid-column: 2 / span 12;
        }
        .ctrtext {
            grid-column: 2 / span 12;
        }
    }
}

//styling
.bl-aktuelltext {
}

//typo
.bl-aktuelltext {
    @include underlinelist;
}
