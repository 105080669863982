:root {
    @include rem-calc-sl(--lrminmargin, 100);
    @include rem-calc-sl(--layoutgridgap, 16);

    --layoutgridwidth:min(1640px, calc(100vw - var(--lrminmargin))); //1640 in XD Desktop
    --lrmargin:calc((100vw - var(--layoutgridwidth)) / 2);
    --layoutgridcolwidth:calc((var(--layoutgridwidth) - 11 * var(--layoutgridgap)) / 12);
}

@mixin grid {
    display:grid;
    gap:var(--layoutgridgap);
    & > * {
        min-width:0;
    }
}
@mixin grid12 {
    @include grid;
    grid-template-columns: repeat(12, 1fr);
}
@mixin grid12lr {
    @include grid;
    grid-template-columns: 1fr repeat(12, var(--layoutgridcolwidth)) 1fr;
    gap:var(--layoutgridgap);
}
@mixin gridrow1 {
    & > * {
        grid-row:1;
    }
}
@function gridcolwidth($numcols) {
    @return calc($numcols * var(--layoutgridcolwidth) + ($numcols - 1) * var(--layoutgridgap));
}