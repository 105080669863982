//positioning
.bl-imageblock {
    @include grid12lr;
    @include breakpoint(large) {
        &.layout4c {
            .ctrimg {
                grid-column:6 / span 4;
            }
        }
    }
    @include breakpoint(small down) {
        .ctrimg {
            grid-column:2 / span 12;
        }
    }
}

//styling
.bl-imageblock {
    img {
        @include w100bl;
    }
}