@mixin underlinelist($itemgap: 1.5em) {
  ul {
    @include reset-list;
    li {
        display:flex;
        align-items: flex-start;
        &::before {
            content: "";
            flex-shrink: 0;
            width:0.75em;
            height:2px;
            @media (-webkit-min-device-pixel-ratio: 2) {
              height:1.5px;
            }
            margin-top:1em;
            border-radius:99px;
            background-color: currentColor;

            margin-right: 0.25em;            
        }
    }
    li + li {
      margin-top: $itemgap;
    }
  }
}
