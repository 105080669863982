//positioning
.bl-aktuellhero {
    @include grid12lr;
    @include breakpoint(medium) {
        .ctrimage {
            grid-column: 1 / span 14;
        }
        .ctrlegend {
            grid-column: 2 / span 2;
        }
    }
    @include breakpoint(small down) {
        .ctrimage {
            grid-column: 1 / span 14;
        }
        .ctrlegend {
            grid-column: 2 / span 12;
        }
    }
}

//styling
.bl-aktuellhero {
    .ctrimage {
        img {
            @include w100bl;
        }
    }
    .ctrlegend {
        .legend {
            @include imglegend;
        }
    }
}

