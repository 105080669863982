//positioning
.bl-homevision {
    @include grid12lr;

    @include breakpoint(medium) {
        .ctrillu {
            grid-column: 3 / span 5;
            transform: translateX(calc(0.5*var(--layoutgridcolwidth)));
        }
        .ctrtitle {
            grid-column: 8 / span 4;
            align-self: end;
        }
        .ctrtext1 {
            grid-column: 8 / span 4;
        }
        .ctrtext2 {
            grid-column: 4 / span 4;
            padding-right:calc(0.5 * var(--layoutgridcolwidth));
            margin-top:-2em; //ineinander verschieben
        }
        .ctrtext3 {
            grid-column: 8 / span 4;
            grid-row:4;
            margin-top:-2em; //ineinander verschieben
        }
        .ctrlinks {
            grid-column: 8 / span 4;
            grid-row:5;
        }
    }
    @include breakpoint(small down) {
        .ctrillu {
            grid-column: 1 / span 14;
        }
        .ctrtitle, .ctrtext1, .ctrtext2, .ctrtext3 {
            grid-column: 2 / span 12;
            margin-top:$smallColSeparatorMargin;
        }
        .ctrlinks {
            grid-column: 2 / span 12;
        }
    }
}

.bl-homevision {
    .ctrlinks {
        margin-top: 6em;
        @include breakpoint(small down) {
            margin-top:$smallColSeparatorMargin;
        }
    }
}

//typo
.bl-homevision {
    @include contentlinkwitharrow;
    --contentlinkcolor:#{$cyan};

    .illu {
        @include w100bl;
    }

    .ctrlinks {
        @include typo-smalllink;
    }
}