//Menu: see desktopmenu.scss

//positioning
header {
  @include grid12lr;

  .ctrlr {
    grid-column: 2 / span 12;

    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}

:root {
  @include rem-calc-sl(--headerlogowidth, 164, 0.7);
  @include rem-calc-sl(--headerpaddingtop, 134, 0.5);
  @include breakpoint(medium down) {
    --headerpaddingtop: #{rem-calc(30)};
  }
}
//styling
header {
  //@include nomargincollapse;
  padding-top:var(--headerpaddingtop);
  @include rem-calc-sl(padding-bottom, 162, 0.25);

  .ctrlogo {
    //width:calc(1.35 * var(--layoutgridcolwidth));
    //@include rem-calc-sl(width, 164, 0.7);
    width:var(--headerlogowidth);
    img {
      @include w100bl;
    }
  }
}



