//positioning
.bl-loginmitglieder {
    @include grid12lr;
    @include breakpoint(medium) {
        .ctrtitle {
            grid-column:4 / span 8;
        }
        .ctrmgmitstimmb {
            grid-column:4 / span 4;
            padding-right:calc(var(--layoutgridcolwidth) * 0.5);
        }
        .ctrmgohnestimmb {
            grid-column:8 / span 4;
        }
        .ctrlinks {
            grid-column:4 / span 8;
            margin-top: 5em;
        }
    }
    @include breakpoint(small down) {
        .ctrtitle, .ctrmgmitstimmb, .ctrmgohnestimmb, .ctrmgohnestimmb, .ctrlinks {
            grid-column:2 / span 12;
        }
        .ctrmgohnestimmb {
            margin-top: 4em;
        }
        .ctrlinks {
            margin-top: 4em;
        }
    }
}

//typo
.bl-loginmitglieder {
    .mglist {
        @include underlinelist($itemgap: 0);
    }

    .ctrlinks {
        @include typo-smalllink;
        @include contentlinkwitharrow;
    }
}
