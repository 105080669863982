//positioning
.bl-loginform {
    @include grid12lr;

    @include breakpoint(medium) {
        & > .linner {
            grid-column: 4 / span 8;
        }
    }
    @include breakpoint(small down) {
        & > .linner {
            grid-column: 2 / span 12;
        }
    }

    .errors:not(:empty) {
        margin-bottom:2em;
    }
}

//styling
.bl-loginform {
    .loginform {
        @extend .mmx-forms;
    }
}

//typo
.bl-loginform .errors {
    color:red;
}