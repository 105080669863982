.cmpmobilegallery {
    overflow-x: auto;

    & > .linner {
        display:flex;
        gap:10px;
    }

    .ctrimage {
        width:90vw;
        flex-shrink: 0;

        img {
            @include w100bl;
            aspect-ratio: 1 / 1;
            object-fit: cover;
            object-position: center center;
        }
    }

    .legend {
        padding:1.5em 1.25em;
        @include imglegend;
    }
}