//positioning
.bl-firmenhierarchie {
    @include grid12lr;
    row-gap: 0;

    h2 {
        margin-bottom: 0;
    }

    @include breakpoint(medium) {
        h2, .ctrvereinigung, .ctrholding {
            grid-column: 4 / span 6;
        }
        .ctrphotometer {
            grid-column: 4 / span 3;
            margin-right:calc(0.25 * var(--layoutgridcolwidth));
        }
        .ctrimmobilien {
            grid-column: 7 / span 3;
        }
        .ctrdownloads {
            grid-column: 4 / span 6;
        }
    }
    @include breakpoint(small down) {
        h2, .ctrvereinigung, .ctrholding {
            grid-column: 2 / span 12;
        }
        .ctrphotometer {
            grid-column: 2 / span 6;
            margin-right:calc(0.25 * var(--layoutgridcolwidth));
        }
        .ctrimmobilien {
            grid-column: 8 / span 6;
        }
        .ctrdownloads {
            grid-column: 2 / span 12;
        }
    }
}

//styling
.bl-firmenhierarchie {
    @mixin boxpadding {
        padding-top:1.25em;
        padding-bottom:1.25em;
    }
    @mixin linetop {
        border-top: 1px solid currentColor;
    }
    .ctrvereinigung {
        @include boxpadding();
    }
    .ctrholding, .ctrphotometer, .ctrimmobilien {
        @include boxpadding();
        @include linetop();
    }
    .ctrdownloads {
        margin-top:3em;
        @include breakpoint(small down) {
            margin-top: 1.5em;
        }
    }
}

//typo
.bl-firmenhierarchie {
    @include contentlinkwitharrow;
    .ctrdownloads {
        @include typo-smalllink;
    }
}