$black:#000;
$white:#fff;

$lightbrown:#E7E4D7;
$darkblue:#003D7C;
$lightblue:#008AFF;
$footerbgstart: $darkblue;
$footerbgend: #001F3E;
$cyan: #A7EFFF;

$footertext:#fff;

$smallColSeparatorMargin:3em;

$hoverLetterSpacing:0.5px;

/*
@mixin borderradius1 {
    //images
    border-radius: 2rem;
}
@mixin borderradius2 {
    //button
    border-radius: 0.5rem;
}
*/

$buttonbg:$darkblue;
$buttonfg:#FFF3DD;

:root {
    //--gap1:2rem;
    //--gap2:4rem;
    --textcolor:#003D7C;
}

