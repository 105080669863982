//positioning
footer {
  @include breakpoint(large) {
    & > .linner {
      @include grid12lr;
    }
    .ctrmain {
      grid-column: 4 / span 8;

      display: flex;
      justify-content: space-between;
    }
    .ctrlogo {
      @include rem-calc-sl(margin-top, -14, 0.5);
    }
    .ctrcopyright {
      margin-top:rem-calc(12);
      margin-right: rem-calc(50);
      margin-left: rem-calc(50);
      text-align: right;
    }
  }
  @include breakpoint(medium down) {
    @include grid12lr;
    & > .linner, .ctrcopyright {
      grid-column: 2 / span 12;
    }
    .ctraddr, .ctrcontact, .navlink:first-of-type, .ctrcopyright {
      margin-top:1.5em;
    }
    .navlink {
      display: block;
    }
  }
}

//styling
footer {
  background: linear-gradient(to bottom, $footerbgstart, $footerbgend);
  
  margin-top:10em;

  //@include nomargincollapse;
  @include rem-calc-sl(padding-top, 84, 0.5);
  @include rem-calc-sl(padding-bottom, 36, 0.5);

  .ctrlogo {
    @include rem-calc-sl(width, 104, 1);
    img {
      @include w100bl;
    }
  }
}

//Menuicon soll auf Mobile hinter footer verschwinden
@include breakpoint(small down) {
  footer {
    position: relative; //enable z-index
    z-index: 201;
  }
}

//typo
footer {
  @include typo-footer;
  color:$lightblue;
  .ctraddr {
    color:$white;
  }

  h2 {
    font:inherit;
    margin:0;
    color:$white;
  }
  .ctrcopyright {
    @include typo-copyright;
  }
}


