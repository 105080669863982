@use "sass:math";

//https://github.com/foundation/foundation-sites/blob/develop/scss/util/_breakpoint.scss
@mixin breakpoint($pointarr) {
  $bp_small_medium: 740px;
  $bp_medium_large: 1024px;
  $bp_large_xlarge: 1700px;

  $point: nth($pointarr, 1);
  $dir: up;
  @if length($pointarr) > 1 {
    $dir: nth($pointarr, 2);
  }
  @if $point == small and $dir == up {
    @media (min-width: 0px) { @content; }
  } @else if $point == small and $dir == down {
    @media (max-width: #{$bp_small_medium - 1px}) { @content; }
  } @else if $point == small and $dir == only {
    @media (min-width: 0px) and (max-width: #{$bp_small_medium - 1px}) { @content; }
  } @else if $point == medium and $dir == up {
    @media (min-width: $bp_small_medium) { @content; }
  } @else if $point == medium and $dir == down {
    @media (max-width: #{$bp_medium_large - 1px}) { @content; }
  } @else if $point == medium and $dir == only {
    @media (min-width: $bp_small_medium) and (max-width: #{$bp_medium_large - 1px}) { @content; }
  } @else if $point == large and $dir == up {
    @media (min-width: $bp_medium_large) { @content; }
  } @else if $point == large and $dir == down {
    @media (max-width: #{$bp_large_xlarge - 1px}) { @content; }
  } @else if $point == large and $dir == only {
    @media (min-width: $bp_medium_large) and (max-width: #{$bp_large_xlarge - 1px}) { @content; }
  } @else if $point == xlarge and $dir == up {
    @media (min-width: $bp_large_xlarge) { @content; }
  } @else {
    @warn "Not a valid breakpoint: "+$pointarr;
  }
}

//https://github.com/foundation/foundation-sites/blob/develop/scss/util/_unit.scss
@function rem-calc($values, $multiplier: 1) {
  $rem-values: ();
  $count: length($values);

  @for $i from 1 through $count {
    $rem-values: append($rem-values, math.div(nth($values, $i), 16) * 1rem * $multiplier);
  }

  @return $rem-values;
}

//example usage: @include rem-calc-sl(padding, 50 0, math.div(20, 50));
@mixin rem-calc-sl($property, $values, $smallfactor: 0.5) {
  @if $smallfactor > 1 {
    @warn Smallfactor bigger than 1;
  }
  $factordiff: 1-$smallfactor;
  $largefactor: 1 - $factordiff*0.5; //0.33
  $mediumfactor: 1 - $factordiff*0.75; //0.66
  #{$property}: rem-calc($values, 1);
  @include breakpoint(large down) {
    #{$property}: rem-calc($values, $largefactor);
  }
  @include breakpoint(medium down) {
    #{$property}: rem-calc($values, $mediumfactor);
  }
  @include breakpoint(small down) {
    #{$property}: rem-calc($values, $smallfactor);
  }
}