//positioning
.bl-vereinerrungenschaften {
    @include grid12lr;

    @include breakpoint(medium) {
        .ctrtitle {
            grid-column: 4 / span 5;
        }
        .ctrintrotext {
            grid-column: 4 / span 4;
            padding-right:calc(var(--lrmargin) * 0.5);
        }

        .ctrimagesdesktop1, .ctrtext1 {
            margin-top:7em;
        }
        .ctrimagesdesktop1 {
            grid-column: 1 / span 7;
        }
        .ctrtext1 {
            grid-column: 9 / span 4;
            padding-right:calc(var(--lrmargin) * 0.5);
        }

        .ctrtext2, .ctrimagesdesktop2 {
            margin-top:7em;
        }
        .ctrtext2 {
            grid-column: 4 / span 4;
            padding-right:calc(var(--lrmargin) * 0.5);
        }
        .ctrimagesdesktop2 {
            grid-column: 8 / span 7;
        }

        .ctrtext3 {
            margin-top:7em;
        }
        .ctrtext3 {
            grid-column: 4 / span 4;
            padding-right:calc(var(--lrmargin) * 0.5);
        }
        .ctrimagesmobile {
            display: none;
        }
    }
    @include breakpoint(small down) {
        .ctrimagesdesktop1, .ctrimagesdesktop2 {
            display: none;
        }
        .ctrtitle, .ctrintrotext {
            grid-column: 2 / span 12;
        }
        .ctrtext1, .ctrtext2, .ctrtext3 {
            grid-column: 2 / span 12;
        }
        .ctrimagesmobile {
            grid-column: 1 / span 14;
        }
        .ctrintrotext, .ctrtext1, .ctrtext2, .ctrtext3, .ctrimagesmobile {
            margin-top: 0.75em;
        }
        .ctrtext3 {
            margin-top:3em;
        }
        .ctrimagesmobile {
            margin-top: 3em;
        }
    }
}

//styling
.bl-vereinerrungenschaften {
}

//typo
.bl-vereinerrungenschaften {
    .ctrtext3 {
        @include underlinelist;
    }
}