//positioning
.bl-firmenphotometer {
    @include grid12lr;

    @include breakpoint(medium) {
        .ctrtext1, .ctrtext3 {
            padding-right:calc(0.5 * var(--layoutgridcolwidth));
        }
        .ctrtext1 {
            grid-column: 4 / span 4;
            grid-row:1;
        }
        .ctrtext2 {
            grid-column: 8 / span 3;
            grid-row:2;
            margin-top:-3em; //ineinander verschieben
        }
        .ctrtext3 {
            grid-column: 4 / span 4;
            grid-row:3;
            margin-top:-3.5em; //ineinander verschieben
        }
    }

    @include breakpoint(small down) {
        .ctrtext1, .ctrtext2, .ctrtext3 {
            grid-column: 2 / span 12;
        }
        .ctrtext2, .ctrtext3 {
            //margin-top:$smallColSeparatorMargin;
            margin-top:1.5em;
        }
    }
}

//styling
.bl-firmenphotometer {
    .ctrtext2, .ctrtext3 {
        @include underlinelist;
    }
    .ctrtext3 .cmplogolink {
        margin-top: 2em;
    }
}

//typo
.bl-firmenphotometer {
}