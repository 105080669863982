@mixin imglegend {
    &:before {
        content: "";
        display: block;
        width:0.75em;
        height:2px;
        background-color: currentColor;
        border-radius: 99px;
        margin-bottom:0.25em;
    }
    @include typo-legend;
}

.cmpimagesdesktop2bs {
    .ctrimages {
        position: relative;
        .ctrimage1 {
            width:70%;
        }
        .ctrimage2 {
            width:40%;
            position: absolute;
            right:0;
            //bottom:4em;
            bottom:0;

            transform: translateY(calc(var(--scrollpercent)*-200px));
            transition: transform 0.05s;
        }

        img {
            @include w100bl;
        }
    }
    .ctrlegend {
        padding-top: 1em;
        padding-left: var(--layoutgridcolwidth);
        padding-right: var(--layoutgridcolwidth);
        max-width:calc(3 * var(--layoutgridcolwidth));

        .legend + .legend {
            margin-top: 1.25em;
        }

        .legend {
            @include imglegend;
        }
    }
}

.cmpimagesdesktop1 {
    @extend .cmpimagesdesktop2bs;
    .ctrimages {
        .ctrimage1 {
            width:100%;
        }
    }
}