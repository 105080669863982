//positioning
.bl-museummeilensteine {
    @include grid12lr;

    @include breakpoint(medium) {
        .ctrimagesdesktop1 {
            grid-column: 1 / span 6;
            grid-row:1;
            margin-bottom: 4em;
        }
        .ctrtitle {
            grid-column: 9 / span 5;
            grid-row:1;
            align-self: center;
            padding-left: calc(0.5 * var(--layoutgridcolwidth));
        }
        .ctrgatitle {
            grid-column: 4 / span 3;
        }
        .ctrimagesdesktop2 {
            grid-column: 8 / span 7;
        }
        .ctrimagesdesktop3 {
            grid-column: 1 / span 7;
            margin-top: -8em;
        }
        .ctrimagesdesktop4 {
            grid-column: 8 / span 7;
            margin-top:8em;
        }
        .ctrimagesmobile {
            display: none;
        }
    }
    @include breakpoint(small down) {
        .ctrimagesdesktop1, .ctrimagesdesktop2, .ctrimagesdesktop3, .ctrimagesdesktop4 {
            display: none;
        }
        .ctrtitle {
            grid-column: 2 / span 12;
        }
        .ctrgatitle {
            grid-column: 2 / span 12;
            margin-top:0.75em;
        }
        .ctrimagesmobile {
            grid-column: 1 / span 14;
            margin-top:0.75em;
        }
    }
}

//styling
.bl-museummeilensteine {
    .ctrimagesdesktop1 {
        img {
            @include w100bl;
        }
        .legend {
            padding-top: 1em;
            padding-left: var(--layoutgridcolwidth);
            @include imglegend;
        }
    }

    .ctrimagesdesktop2, .ctrimagesdesktop4 {
        .legend {
            max-width:calc(3 * var(--layoutgridcolwidth));
        }
    }
}

//typo
.bl-museummeilensteine {
}