//global font-size (small, medium, large)
// main {
//   font-size: rem-calc(18);
//   @include breakpoint(medium) {
//     font-size: rem-calc(21);
//   }
//   @include breakpoint(large) {
//     font-size: rem-calc(24);
//   }
// }

@mixin typo-base {
  //Dynamic Font Size (mobile, desktop)
  @include font-size-pt(16, 20);
  font-weight: 500; //500=Medium
  letter-spacing: 0em;
  line-height:math.div(29, 20);
}

body {
  font-family:'Plus Jakarta Sans', sans-serif;
  @include typo-base;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:var(--textcolor);
}
a {
  color:inherit;
  text-decoration: none;
}
a:focus {
  outline: none;
}

@mixin typo-h1 {
  font-family:'Cormorant Upright', sans-serif;
  @include font-size-pt(46, 76);
  font-weight: 400;
  line-height: math.div(85, 76);
}
@mixin typo-h2 {
  @include font-size-pt(22, 25);
  font-weight: 700;
  line-height: math.div(36, 25);
}
@mixin typo-h3 {
  font:inherit;
  font-weight: 700;
}
@mixin typo-h4 {
  @include typo-h3;
}

@mixin typo-footer {
  @include font-size-pt(16, 16);
  font-weight: 600;
  letter-spacing: 0em;
  line-height:math.div(26, 16);
}
@mixin typo-menu {
  @include typo-base;
}
@mixin typo-submenu {
  @include font-size-pt(16, 16);
  font-weight: 600; //600=Semibold
  letter-spacing: 0em;
  line-height:math.div(23, 16);
}
@mixin typo-smalltext {
  @include font-size-pt(16, 16);
  font-weight: 600; //600=Semibold
  letter-spacing: 0em;
  line-height:math.div(26, 16);
}
@mixin typo-xsmalltext {
  @include font-size-pt(14, 14);
  font-weight: 600; //600=Semibold
  letter-spacing: 0em;
  line-height:math.div(20, 14);
}
@mixin typo-button {
  @include typo-xsmalltext;
}
@mixin typo-smalllink {
  @include typo-xsmalltext;
}
@mixin typo-copyright {
  @include font-size-pt(12, 12);
  font-weight: 600; //600=Semibold
  letter-spacing: 0em;
  line-height:math.div(26, 12);
}
@mixin typo-legend {
  @include typo-xsmalltext;
}

strong {
  font-weight: 700;
}

address {
  font:unset; //remove italic browser style
}

p {
  margin:0 0 1.25em 0;
}
p:last-child {
  margin-bottom:0;
}
p a {
  //text-decoration: underline;
  //text-decoration-thickness: 1px;
}

h1, h2 {
  margin:0 0 0.75em 0;
}
h3, h4 {
  margin:0;
}
h1 {
  @include typo-h1;
}
h2 {
  @include typo-h2;
}
h3 {
  @include typo-h3;
}
h4 {
  @include typo-h4;
}

@mixin text-decoration-underline {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 0.1em;
}