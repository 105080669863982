//positioning
.bl-heroillu {
    @include grid12lr;

    --illucols: 5;
    .ctrillu {
        grid-column: 3 / span var(--illucols);
    }
    .ctrtitle {
        grid-column: 8 / span 4;
        
        //V1: centered
        //align-self: center;

        //V2: oben angeschlagen
        align-self: start;
        margin-top:9em;
    }
    /*
    @include breakpoint(medium down) {
        --illucols: 6;
        .ctrillu {
            grid-column: 2 / span var(--illucols);
        }
        .ctrtitle {
            grid-column: 8 / span 6;
        }
    }
    */
    @include breakpoint(small down) {
        .ctrillu {
            grid-column: 1 / span 14;
        }
        .ctrtitle {
            grid-column: 2 / span 12;
            margin-top:1em;
        }
    }
}

//styling
.bl-heroillu {
    .ctrillu {
        position: relative;
        z-index:-10;
        //aspect-ratio: 1;
        
        pointer-events: none;
    }
    .illubg, .illufg {
        display: block;
        width:gridcolwidth(var(--illucols));
        @include breakpoint(small down) {
            width:100vw;
        }
        height:auto;
    }
    .illubg {
        position: relative; //Bei der Lampe im Code im Contentblock Reihenfolge umdrehen. Und hier relative/fixed tauschen.
        z-index: 1;
        transition: opacity .15s ease;
        &.opaque {
            opacity: 0;
        }
    }
    .illufg {
        position: fixed;
        z-index: 2;
    }
    &.illu-lampe {
        .illubg {
            position:fixed;
        }
        .illufg {
            position:relative;
        }
    }

    /*
    &.notinview {
        .ctrillu {
            opacity: 0;
        }
    }
    */
}