:root {
    --contentlinkcolor:#{$lightblue};
}
@mixin contentlinkwitharrow {
    a {
        font-weight: 600; //Semibold

        display:flex;
        &::before {
            content:"x";
            color:transparent;
            flex-shrink: 0;
            flex-grow: 0;
            height:1.4em;
            width:2px;
            margin-right: 0.5em;
            background-color: var(--contentlinkcolor);
            border-radius: 99px;

            transition:all .2s;
        }
        @media (hover:hover) {
            &::after {
                content:"";
                flex-shrink: 0;
                flex-grow: 0;
                //position: absolute;
                opacity: 0;
                margin-top:0.25em;
                width:1em;
                height:1em;
                margin-left: 0.5em;
                background-color: var(--contentlinkcolor);
                mask-image: url('/assets/app/images/linkarrowright.svg');
                mask-size: contain;
                mask-position: center center;
                mask-repeat: no-repeat;

                transform:translateX(-50px);
                transition:all .2s;
            }

            &:hover {
                color:var(--contentlinkcolor);
                letter-spacing: $hoverLetterSpacing;
                &::before {
                    opacity: 0;
                    transform: translateX(50px);
                }
                &::after {
                    opacity: 1;
                    transform:translateX(0);
                }
            }
        }
    }
}