.bl-richtext {
    @include grid12lr;
    @include breakpoint(medium) {
        .ctrtext {
            grid-column: 4 / span 8;
        }
    }
    @include breakpoint(small down) {
        .ctrtext {
            grid-column: 2 / span 12;
        }
    }

    /*
    &.dashlist {
        ul {
            @include reset-list;
        }
        li:before {
            //reset default bullet
            display: none;
        }

        li {
            border-top:2px dashed currentColor;
            padding:0.4em 0 0.2em 0;
        }
        ul {
            border-bottom:2px dashed currentColor;
            font-weight: 300;
        }
    }
    */
}

//typo
.bl-richtext {
    &.lead1 {
        //@include typo-lead;
    }
    a {
        @include text-decoration-underline;
    }
}

