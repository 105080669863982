//positioning
.bl-aktuelllist {
    @include grid12lr;

    .ctrtitle {
      grid-column: 4 / span 8;
  
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
    .ctritems {
        grid-column: 5 / span 6;

        & > .linner {
            @include grid;
            grid-template-columns: repeat(3, 1fr);
            @include rem-calc-sl(gap, 70, 0.5);
        }
    }

    @include breakpoint(small down) {
        .ctrtitle {
            grid-column: 2 / span 12;
            margin-bottom:1em;
        }
        .ctritems {
            grid-column: 1 / span 14;
            overflow-x:auto;
            padding-bottom:2em; //mobile scrollbar soll nicht ueber den Elementen liegen
            & > .linner {
                width:200vw;
                padding-left:calc(var(--lrmargin) * 0.5);
                padding-right:calc(var(--lrmargin) * 0.5);
            }
        }
    }

    @include breakpoint(medium) {
        .ctrmoreitemstextlist {
            display:none;
        }
    }
    @include breakpoint(small down) {
        .ctrmoreitemstextlist {
            grid-column: 2 / span 12;
            margin-top:4em;
        }
    }
}
  
//styling
.bl-aktuelllist {
    @mixin underlineborder {
        padding-bottom:0.35em;
        border-bottom:1px solid currentColor;
        margin-bottom: 0.35em;
    }
    .ctrtitle {
        h2 {
            margin-bottom: 0;
        }
        @include contentlinkwitharrow;
        a {
            @include typo-smalllink;
        }
    }
    .allitems {
        cursor: pointer;
    }
    .ctritems {
        margin-top: 3em;
        @include breakpoint(small down) {
            margin-top: 1.5em;
        }
    }
    .item {
        .ctrovimg {
            border-radius: 9999px;
            overflow: hidden;
            img {
                @include w100bl;
                transition: transform .2s;
            }

            position: relative;
            .imgoverlay {
                @include absposfull;
                background: linear-gradient(to bottom, transparent 50%, $darkblue);
                opacity: 0;
                transition: opacity .2s;
            }
        }
        .ctrinfo {
            margin-top:1em;
            text-align: center;
            h3 {
                @include underlineborder;
            }
        }
        .ctrbuttons {
            margin-top:1em;
            display:flex;
            justify-content: center;
        }
    }
    .item:hover {
        .button {
            @include buttonhover;
        }
        .ctrovimg {
            img {
                transform:scale(1.1);
            }
            .imgoverlay {
                opacity: 0.5;
            }
        }
    }

    //show only first 3 items by default
    .item:nth-child(1n+4) {
        display:none;
    }

    .ctrmoreitemstextlist {
        h3 {
            @include underlineborder;
        }
        ul {
            @include reset-list;
            @include contentlinkwitharrow;
            a {
                padding-top:0.7em;
                padding-bottom:0.7em;
            }
        }
    }
}

//typo
.bl-aktuelllist {
    h3 {
        @include typo-smalltext;
        font-weight:700;
    }
    .subtitle {
        @include typo-xsmalltext;
    }
}
