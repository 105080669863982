//positioning
.bl-2img {
    @include grid12lr;

    @include breakpoint(medium) {
        .ctrimagesdesktop {
            grid-column: 8 / span 7;
        }
        &.layout-l .ctrimagesdesktop {
            grid-column: 1 / span 7;
        }
        .ctrimagesmobile {
            display: none;
        }
    }
    @include breakpoint(small down) {
        .ctrimagesdesktop {
            display: none;
        }
        .ctrtitle, .ctrtext {
            grid-column: 2 / span 12;
        }
        .ctrimagesmobile {
            grid-column: 1 / span 14;
        }
    }
}

//styling
.bl-2img {
    .ctrlegend {
        max-width:calc(2.75 * var(--layoutgridcolwidth));
    }
}