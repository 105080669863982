//positioning
.bl-museumbesichtigungen {
    @include grid12lr;

    @include breakpoint(medium) {
        & > .linner {
            grid-column: 4 / span 8;
        }
        .ctraddress .ctrcols {
            display:flex;
            gap:var(--layoutgridcolwidth);
        }
    }
    @include breakpoint(small down) {
        & > .linner {
            grid-column: 2 / span 12;
        }
    }

    .ctrform {
        margin-top: 4em;
    }
    .ctraddress {
        margin-top: 2em;
        @include breakpoint(small down) {
            margin-top: 4em;
        }
    }
    .ctrlinks {
        margin-top:2em;
    }
}

//styling
.bl-museumbesichtigungen {
}

//typo
.bl-museumbesichtigungen {
    .ctraddress h2 {
        font:inherit;
        margin-bottom:0;
    }
    .ctrlinks {
        @include typo-smalllink;
        @include contentlinkwitharrow;
    }    
}