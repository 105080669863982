//positioning
.bl-firmenholding {
    @include grid12lr;

    @include breakpoint(medium) {
        .ctrtitle {
            grid-column: 4 / span 8;
        }
        .ctrtext1 {
            grid-column: 4 / span 4;
            padding-right: calc(0.5 * var(--layoutgridcolwidth));
        }
        .ctrtext2 {
            grid-column: 9 / span 3;
        }

        h2 {
            margin-bottom: 2em;
        }
    }
    @include breakpoint(small down) {
        .ctrtitle, .ctrtext1, .ctrtext2 {
            grid-column: 2 / span 12;
        }
        .ctrtext2 {
            margin-top: 0.75em;
        }
    }
}

//typo
.bl-firmenholding {
    .ctrtext2 {
        @include underlinelist;
    }
}