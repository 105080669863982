//positioning
.bl-audioplayer {
    @include grid12lr;

    audio {
        width:100%;
    }

    @include breakpoint(medium) {
        .ctrintrotext, .ctrplayer, .ctrfiles {
            grid-column: 4 / span 4;
            padding-right: calc(0.5 * var(--layoutgridcolwidth));
        }
        .ctrplayer {
            margin-top:1.5em;
        }
    }
    @include breakpoint(small down) {
        .ctrintrotext, .ctrplayer, .ctrfiles {
            grid-column: 2 / span 12;
        }
        .ctrplayer, .ctrfiles {
            margin-top: 0.75em;
        }
    }
}

//style <audio> Tag (Chrome)
//https://blog.shahednasser.com/how-to-style-an-audio-element/
.bl-audioplayer audio {
    &::-webkit-media-controls-panel {
        background-color: #1C417A;
    }
    &::-webkit-media-controls-play-button,
    &::-webkit-media-controls-timeline,
    &::-webkit-media-controls-mute-button,
    &::-webkit-media-controls-volume-slider,
    &::-webkit-media-controls-fullscreen-button
    {
        filter: brightness(0) saturate(100%) invert(48%) sepia(61%) saturate(3300%) hue-rotate(199deg) brightness(102%) contrast(98%);        
    }
    &::-webkit-media-controls-current-time-display,
    &::-webkit-media-controls-time-remaining-display {
        color:#FDF2DD;
        text-shadow: none;
    }
}

//typo
.bl-audioplayer {
    .ctrfiles {
        @include underlinelist($itemgap: 0.75em);
        @include typo-smalllink;

        .active {
            color:#008AFF;
        }
    }
}