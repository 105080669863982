.bl-logoutbutton {
    @include grid12lr;

    @include breakpoint(medium) {
        & > .linner {
            grid-column: 4 / span 8;
        }
    }
    @include breakpoint(small down) {
        & > .linner {
            grid-column: 2 / span 12;
        }
    }
}