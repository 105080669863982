.mmx-forms {
    @include breakpoint(medium) {
        .vf-row {
            @include grid;
            grid-template-columns: 1fr 1fr;
        }
        .sizespan2 {
            grid-column-end: span 2;
        }
    }
    @include breakpoint(small down) {
        .vf-element-layout + .vf-element-layout {
            margin-top:1em;
        }
    }

    .ctrsubmit {
        margin-left: auto;
    }

    input, textarea, select {
        border:1px solid $darkblue;
        border-radius:1em;
        font:inherit;
        padding:0.35em 1em 0.25em 1em;
        @include breakpoint(small down) {
            padding-top:0.55em;
            padding-bottom:0.45em;
        }

        width: 100%;
        box-sizing: border-box;

        outline:none;

        &::placeholder {
            color: transparent;
        }
        background-color: transparent;
        &:autofill {
            transition: all 100000s; //Hack to disable yellow background on autofill (saved password)
        }
        color:inherit;
    }
    textarea {
        height:7em;
    }

    /*
    .vf-element-layout + .vf-element-layout {
        margin-top:0.75em;
    }
    */

    .vf-element-layout-outer-wrapper {
        position: relative;
            
        label {
            position: absolute;
            left:1.5em;
            top:0.2em;
            
            pointer-events: none;

            transition: all .2s;
            transform-origin: left top;

            color:$lightblue;
            font-size:0.75em;
        }

        &:focus-within,
        &:not(:has(input:placeholder-shown)):not(:has(textarea:placeholder-shown))
        {
            label {
                //left:1em;
                top:-0.6em;
                //transform: scale(0.5);
                background-color: var(--currentbgcolor);
            }
        }        
    }
}
/*
.form-terminform {
    input, textarea, select {
        border:none;
        border-radius:0;
        font:inherit;
        padding:0.1em 0;

        width: 100%;
        box-sizing: border-box;

        outline:none;

        &::placeholder {
            color: $black;
            opacity: 1;
        }
    }
    .vf-element-layout:has(input) {
        border-bottom:2px solid $black;
    }
    .vf-element-layout-outer-wrapper:has(:is(input[type=date], input[type=time])) {
        display:flex;
        gap:0.5em;
    }
    .vf-element-layout:has(button) {
        margin-top:1em;
    }
}
*/

//typo
.mmx-forms {
    .vf-element-error {
        color:red;
        font-size:0.75em;
    }
}
