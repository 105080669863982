//positioning
.bl-geschichteeinleitung {
    @include grid12lr;

    h2 {
        margin-bottom: 0.2em;
    }

    @include breakpoint(medium) {
        .ctrtitle {
            grid-column: 4 / span 8;
        }
        .ctrtext1 {
            grid-column: 4 / span 4;
            padding-right: calc(0.5 * var(--layoutgridcolwidth));
        }
        .ctrtext2 {
            grid-column: 8 / span 4;
            padding-right: calc(0.5 * var(--layoutgridcolwidth));
        }
    }
    @include breakpoint(small down) {
        .ctrtitle, .ctrtext1, .ctrtext2 {
            grid-column: 2 / span 12;
        }
        .ctrtext1, .ctrtext2 {
            margin-top: 0.75em;
        }
    }
}