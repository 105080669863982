//positioning
.bl-aktuellimages {
    .ctrimagesdesktop {
        @include grid12lr;
        .ctritem:nth-child(odd) {
            grid-column:8 / span 5;
        }
        .ctritem:nth-child(even) {
            grid-column:1 / span 5;
        }
        @for $i from 1 through 10 {
            .ctritem:nth-child(#{$i}) { 
                grid-row:$i;
            }
        }
    }

    @include breakpoint(medium) {
        .ctrimagesmobile {
            display:none;
        }
    }
    @include breakpoint(small down) {
        .ctrimagesdesktop {
            display:none;
        }
    }
}