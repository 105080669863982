.cmplogolink {
    display: flex;
    align-items: flex-end;
    @include breakpoint(medium) {
        gap:rem-calc(70);
    }
    @include breakpoint(small down) {
        gap:rem-calc(20);
    }

    .ctrlogo {
        img {
            display: block;
            width: rem-calc(140);
        }
    }
    .ctrtextlink {
        @include contentlinkwitharrow;
        @include typo-smalllink;
        margin-bottom: 1em;
    }
}