//positioning
.bl-downloads {
    @include grid12lr;
    @include breakpoint(medium) {
        .ctrdownloads {
            grid-column: 4 / span 8;
        }
    }
    @include breakpoint(small down) {
        .ctrdownloads {
            grid-column: 2 / span 12;
        }
    }
}

//styles
.bl-downloads {
    @include contentlinkwitharrow;
    @include typo-smalllink;
}