//positioning
.bl-firmenimmobilien {
    @include grid12lr;

    @include breakpoint(medium) {
        .ctrtext1, .ctrtext3 {
            padding-right:calc(0.5 * var(--layoutgridcolwidth));
        }
        .ctrtext1 {
            grid-column: 4 / span 4;
            grid-row:1;
        }
        .ctrtext2 {
            grid-column: 8 / span 4;
            grid-row:2;
            margin-top:-3em; //ineinander verschieben
            padding-left:calc(0.5 * var(--layoutgridcolwidth));
        }
        .ctrtext3 {
            grid-column: 4 / span 4;
            grid-row:3;
            margin-top:-3.5em; //ineinander verschieben
        }
        .ctrtext4 {
            grid-column: 8 / span 4;
            grid-row:4;
            margin-top:-2.5em; //ineinander verschieben
            padding-left:calc(0.5 * var(--layoutgridcolwidth));
        }
    }

    @include breakpoint(small down) {
        .ctrtext1, .ctrtext2, .ctrtext3, .ctrtext4 {
            grid-column: 2 / span 12;
        }
        .ctrtext2, .ctrtext3, .ctrtext4 {
            margin-top:$smallColSeparatorMargin;
        }
    }
}

//styling
.bl-firmenimmobilien {
    .cmplogolink {
        margin-top:3em;
    }
    @include breakpoint(small down) {
        .ctrlogo img {
            width: rem-calc(120);
        }
    }
}

//typo
.bl-firmenimmobilien {
    .ctrtext2, .ctrtext4 {
        @include underlinelist;
    }
    .ctrlinks {
        @include typo-smalllink;
        @include contentlinkwitharrow;
    }
}