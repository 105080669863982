//part of library

.pagedmenu {
    ul {
        @include reset-list;
    }

    a {
        display:block;
    }

    .backlink {
        cursor: pointer;
    }
}

.pagedmenu {
    animation-duration: .2s;
}
@keyframes pagedmenu_disappear_left {
	from {opacity:1;}
	to {transform:translateX(-1rem);opacity:0;}
}
@keyframes pagedmenu_appear_left {
	from {transform:translateX(1rem);opacity:0;}
	to {opacity:1;}
}
@keyframes pagedmenu_disappear_right {
	from {opacity:1;}
	to {transform:translateX(1rem);opacity:0;}
}
@keyframes pagedmenu_appear_right {
	from {transform:translateX(-1rem);opacity:0;}
	to {opacity:1;}
}
