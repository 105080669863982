//positioning
.bl-homeeinleitung {
    @include grid12lr;

    @include breakpoint(medium) {
        .ctrtext1, .ctrtext2, .ctrtext3 {
            padding-right:calc(0.5 * var(--layoutgridcolwidth));
        }
        .ctrtext1 {
            grid-column: 8 / span 4;
            grid-row:1;
        }
        .ctrtext2 {
            grid-column: 4 / span 4;
            grid-row:2;
            margin-top:-7em; //ineinander verschieben
        }
        .ctrtext3 {
            grid-column: 8 / span 4;
            grid-row:3;
            margin-top:-3.5em; //ineinander verschieben
        }
    }

    @include breakpoint(small down) {
        .ctrtext1, .ctrtext2, .ctrtext3 {
            grid-column: 2 / span 12;
        }
        .ctrtext2, .ctrtext3 {
            margin-top:$smallColSeparatorMargin;
        }
    }
}

//styling
.bl-homeeinleitung {
    .ctrtext1 {
        ul {
            @include reset-list;
            margin-top:1.5em;
            li {
                padding-bottom: 1.25em;
            }
            li + li {
                padding-top:1.25em;
                border-top: 1px solid currentColor;
            }
        }
    }
}

//typo
.bl-homeeinleitung {
    @include contentlinkwitharrow;
}