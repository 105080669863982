#menubuttonmobile {
    position: fixed;
    z-index: 200;
    right:var(--lrmargin);
    bottom:var(--lrmargin);
    width:rem-calc(47);
    height:rem-calc(47);
    background-color: $darkblue;
    border-radius: 999px;
    cursor: pointer;
    
    $lineWidth: 55%;
    $lineThickness: 2px;
    .line1, .line2 {
        position: absolute;
        top:calc(50% - 0.5*$lineThickness);
        left:0.5 * (100% - $lineWidth);
        height:$lineThickness;
        border-radius: 99px;
        background-color: $lightblue;
        width:$lineWidth;
    }
    .line1, .line2 {
        transition: transform .2s;
    }
    .line1 {
        transform: translateY(-4px);
    }
    .line2 {
        transform: translateY(4px);
    }

    transition: transform .4s;
    &.active {
        transform: scale(1.5);
        .line1, .line2 {
            background-color:$cyan;
        }
        .line1 {
            transform: rotateZ(45deg);
        }
        .line2 {
            transform: rotateZ(-45deg);
        }
    }
}

@include breakpoint(large) {
    #menubuttonmobile {
        display: none;
    }
}