//positioning
.bl-firmennachhaltig {
    @include grid12lr;

    @include breakpoint(medium) {
        .ctrtitle {
            grid-column: 8 / span 7;
            padding-left: calc(0.5 * var(--layoutgridcolwidth));
            align-self: center;
        }
        .ctrimagesdesktop {
            grid-column: 1 / span 7;
        }
        .ctrimagesmobile {
            display: none;
        }
    }
    @include breakpoint(small down) {
        .ctrimagesdesktop {
            display: none;
        }
        .ctrimagesmobile {
            grid-column: 1 / span 14;
        }
        .ctrtitle {
            grid-column: 2 / span 12;
            margin-top:4em;
        }
    }
}

//styling
/*
.bl-firmennachhaltig {
    .cmpimagesdesktop2bs {
        //Exception for image2 position
        .ctrimage2 {
            bottom:0;
            transform: translateY(50%);
        }
    }
    padding-bottom:10vw; //compensate image overlap
}
*/

//typo
.bl-firmennachhaltig {
}