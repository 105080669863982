//positioning
.bl-geschichteereignisse {
    @include grid12lr;

    h2 {
        margin-bottom: 0.2em;
    }

    @include breakpoint(medium) {
        .ctrtitle {
            grid-column: 4 / span 4;
            grid-row:1;
        }
        .ctrtext {
            grid-column: 4 / span 4;
            grid-row:2;
            padding-right: calc(0.5 * var(--layoutgridcolwidth));
        }
        .ctrimagesdesktop1 {
            grid-column: 8 / span 7;
            grid-row:2;
            margin-top:3.5em;
        }
        .ctrimagesdesktop2 {
            grid-column: 1 / span 7;
            grid-row:3;
            margin-top:3.5em;
        }
        .ctrimagesdesktop3 {
            grid-column: 8 / span 7;
            grid-row:4;
            margin-top:2em;
        }
        .ctrimagesdesktop4 {
            grid-column: 1 / span 6;
            grid-row:5;
            margin-top:-10em;
        }
        .ctrimagesdesktop5 {
            grid-column: 8 / span 7;
            grid-row:6;
            margin-top:-10em;
        }
        .ctrimagesmobile {
            display: none;
        }
    }
    @include breakpoint(small down) {
        .ctrimagesdesktop1,
        .ctrimagesdesktop2,
        .ctrimagesdesktop3,
        .ctrimagesdesktop4
        {
            display: none;
        }
        .ctrtitle, .ctrtext {
            grid-column: 2 / span 12;
        }
        .ctrimagesmobile {
            grid-column: 1 / span 14;
            margin-top:3em;
        }
    }
}

//styling
.bl-geschichteereignisse {
}

//typo
.bl-geschichteereignisse {
    .ctrtext {
        @include underlinelist;
    }
}