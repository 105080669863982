//positioning
.bl-homeleitwerte {
    @include grid12lr;
    @include breakpoint(medium) {
        .ctrtitle {
            grid-column: 4 / span 5;
            grid-row:1;
        }
        .ctrtext1 {
            grid-column: 4 / span 3;
            grid-row:2;
        }
        .ctrtext2 {
            grid-column: 6 / span 3;
            grid-row:3;
            margin-top:4em;
        }
        .ctrtext3 {
            grid-column: 9 / span 3;
            grid-row:2;
        }
        .ctrlinks {
            grid-column: 9 / span 3;
            grid-row:3;
            align-self: end;

            display:flex;
            justify-content: flex-end;
            gap:1em;

            @include breakpoint(medium only) {
                //Ausnahme "medium": Hat keinen Platz rechts, daher .ctrlinks darunter positionieren
                grid-column: 6 / span 4;
                grid-row:4;
                justify-content: flex-start;
                margin-top:5em;
            }
        }
    }
    @include breakpoint(small down) {
        .ctrtitle, .ctrtext1, .ctrtext2, .ctrtext3, .ctrlinks {
            grid-column: 2 / span 12;
        }
        .ctrtitle h1 {
            margin-bottom: 0.5em;
        }
        .ctrtext2, .ctrtext3, .ctrlinks {
            margin-top:$smallColSeparatorMargin;
        }
        .ctrlinks {
            a + a {
                margin-top:2em;
            }
        }
    }
}

//styling
.bl-homeleitwerte {
}

//typo
.bl-homeleitwerte {
    @include contentlinkwitharrow;
    @include underlinelist($itemgap: 0);

    .ctrlinks {
        @include typo-smalllink;        
    }

    h2 {
        &::after {
            content:"";
            display: block;
            height:3px;
            background-color: currentColor;
            margin-top:0.75em;
            margin-bottom:1em;
            border-radius: 99px;
        }
    }
}