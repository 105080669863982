//Standardabstand zwischen zwei Bloecken
.cblock + .cblock {
    @include rem-calc-sl(margin-top, 120, 0.5);
}

//Spezifische Abstaende
.bl-richtext + .bl-referenzspecs {
    @include rem-calc-sl(margin-top, 80, 0.5);
}

:is(.bl-imageblock, .bl-textwithimage) + :is(.bl-imageblock, .bl-textwithimage) {
    @include rem-calc-sl(margin-top, 70, 0.5);
}

@include breakpoint(medium) {
    .bl-audioplayer + .bl-videoplayer {
        margin-top:-5em;
    }
}

@include breakpoint(small down) {
    .bl-heroillu + .cblock {
        margin-top:rem-calc(30);
    }
}

/*
.bl-richtext.lead1 + .cblock {
    margin-top:5em;
}

.cblock + .bl-quote,
.bl-richtext.lead1 + .bl-quote {
    margin-top: 8em;
}

*/
