//not in use. see bl-aktuellimages

.bl-aktuellimage {
    @include grid12lr;
    background-color: rebeccapurple;

    &.layoutr {
        .cmpimagesdesktop1 {
            grid-column:8 / span 5;
        }
    }
    &.layoutl {
        .cmpimagesdesktop1 {
            grid-column:1 / span 5;
        }
    }
}