//positioning
.bl-videoplayer {
    @include grid12lr;

    video {
        width:100%;
        aspect-ratio: 1.25;
    }

    @include breakpoint(medium) {
        .ctrintrotext, .ctrfiles {
            grid-column: 8 / span 4;
        }
        .ctrplayer {
            grid-column: 8 / span 5;
        }
        .ctrintrotext, .ctrfiles {
            padding-left: calc(0.5 * var(--layoutgridcolwidth));
        }
        .ctrplayer {
            margin-top:1.5em;
        }
    }
    @include breakpoint(small down) {
        .ctrintrotext, .ctrplayer, .ctrfiles {
            grid-column: 2 / span 12;
        }
        .ctrplayer, .ctrfiles {
            margin-top: 0.75em;
        }
    }
}

//style <video> Tag (Chrome)
.bl-videoplayer video {
    &::-webkit-media-controls-play-button,
    &::-webkit-media-controls-timeline,
    &::-webkit-media-controls-mute-button,
    &::-webkit-media-controls-volume-slider,
    &::-webkit-media-controls-fullscreen-button
    {
        filter: brightness(0) saturate(100%) invert(48%) sepia(61%) saturate(3300%) hue-rotate(199deg) brightness(102%) contrast(98%);        
    }
    &::-webkit-media-controls-current-time-display,
    &::-webkit-media-controls-time-remaining-display {
        color:#FDF2DD;
        text-shadow: none;
    }
}

//typo
.bl-videoplayer {
    .ctrfiles {
        @include underlinelist($itemgap: 0.75em);
        @include typo-smalllink;

        .active {
            color:#008AFF;
        }
    }
}