#desktopmenu {
    @include breakpoint(medium down) {
        display: none;
    }

    /*
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;

    z-index: 50;

    box-sizing: border-box;
    padding-top:rem-calc(80);
    padding-bottom:rem-calc(80);
    padding-left:var(--lrmargin);
    padding-right:var(--lrmargin);
    */

    .dropdownmenu {
        justify-content: flex-end;
        @include rem-calc-sl(--menuitemgap, 65, 0.5);
        gap:var(--menuitemgap);
    }

    /*
    li.level1 {
        position: relative; //allow right:0 on dropdown containers
    }
    li.level1.hassubmenu > ul { //Selector for Dropdown container
        min-width: 7em;

        &.opens-right {
            right:0;
        }
    }
    */

    li.level1:hover > a {
        color:$lightblue;
    }
    li.level1.active > a {
        color:$lightblue;
    }

    li.level1.last {
        //Login
        padding-left:var(--menuitemgap);

        position: relative;
        &::before {
            content:"";
            position: absolute;
            left:0;
            top:0;
            height:100%;
            width:2px;
            border-radius: 99px;
            background-color: currentColor;
        }
    }

    li.level1.hassubmenu > ul { //Selector for Dropdown container
        padding-top:1.2em;
    }

    li.level2 a {
        padding-top: 0.25em;
        padding-bottom: 0.25em;

        &::before {
            content:"x";
            color:transparent;
            display: inline-block;
            width:2px;
            border-radius: 99px;
            background-color: var(--contentlinkcolor);
            margin-right:0.875em; //"14px"

            transition:all .2s;

            //position: absolute; //do not allocate space
            opacity: 0;
            transform:translateX(-1.25em);
        }
        &:hover {
            &::before {
                opacity: 1;
                transform:translateX(1px);
            }
            letter-spacing: $hoverLetterSpacing;
        }
    }
}

/*
#bgdesktopmenudropdown {
    opacity:0;
    transition:opacity .2s;
    pointer-events: none;

    position:fixed;
    inset:0;
    background-color:rgba(#3A0B13, 0.5);
    z-index: 40;
}
.submenuopen #bgdesktopmenudropdown {
    opacity:1;
}
*/

//typo
#desktopmenu {
    @include typo-menu;

    .dropdownmenu ul {
        @include typo-submenu;
    }
}