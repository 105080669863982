//positioning, background
.bl-timeline {
    @include grid12lr;
    @include breakpoint(medium) {
        .linner {
            grid-column: 2 / span 12;
            position: relative;
        }
        .ctrbackground {
            aspect-ratio: 3272 / 1500;
            background-image: url('/assets/app/images/timelinebg-desktop.webp');
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
        }
        .ctrtimeline {
            position: absolute;
            top:50%;
            height:0;
            left:0;
            right:0;
            padding-left:var(--layoutgridcolwidth);
            padding-right:var(--layoutgridcolwidth);

            border-top:1px dashed $darkblue;
        }
    }
    @include breakpoint(small down) {
        .linner {
            grid-column: 1 / span 14;
            position: relative;
        }
        .ctrbackground {
            @include absposfull;
            z-index: -1;
            background-image: url('/assets/app/images/timelinebg-mobile.webp');
            background-size: auto 100%;
            background-position: center center;
            background-repeat: no-repeat;
        }
        .ctrtimeline {
            width:0;
            margin-left: auto;
            margin-right: auto;
            padding-top:5em;
            padding-bottom:5em;

            border-right:1px dashed $darkblue;
        }
    }
}

//styling
.bl-timeline {
    .tlitems {
        display:flex;
        @include breakpoint(medium) {
            flex-direction: row;
        }
        @include breakpoint(small down) {
            flex-direction: column;
            align-items: center;
            height:100vh;
        }
    }
    .tlspacer {
        //outline:3px solid yellow;
        flex-grow: 1; //"Abstand davor": 1,2,3
        &.space2 {
            flex-grow: 2;
        }
        &.space3 {
            flex-grow: 3;
        }
    }
    .tlitem {
        position: relative;
        cursor: pointer;
        .dot {
            position: absolute;
            left:0;
            top:0;
            width:rem-calc(12);
            height:rem-calc(12);
            background-color:$buttonbg;
            border-radius: 99px;
            transform: translate(-50%, -50%);
        }
        .closebutton {
            position: absolute;
            left:0;
            top:0;
            transform: translate(-50%, -50%);
            filter: drop-shadow(0 0 3px var(--currentbgcolor)); //https://stackoverflow.com/questions/22486039/css3-filter-drop-shadow-spread-property-alternatives
            .icon {
                height:rem-calc(18);
                display: block;
            }
        }
        .label {
            position: absolute;
            background-color:$buttonbg;
            border-radius: 99px;
            color:$buttonfg;
            width:fit-content;
            padding:0.25em 1.5em;
            white-space: nowrap;

            transition: all .2s;

            @include breakpoint(medium) {
                left:0;
                transform: translateX(-50%) translateY(var(--labelshift, 0)) scale(var(--labelscale, 1));
            }
            @include breakpoint(small down) {
                top:0;
                transform: translateY(-50%) translateX(var(--labelshift, 0));
            }
        }
        .ctrinfo {
            position: absolute;
            @include breakpoint(medium) {
                left:0;
                width:rem-calc(290);
            }
            @include breakpoint(small down) {
                width:calc(100vw - 2*var(--lrmargin));
                transform:translateX(-50%);
            }

            .background {
                position: absolute;
                inset:-1em;
                transform: scaleX(1.2);
                @include breakpoint(medium) {
                    bottom:0em;
                }
                @include breakpoint(small down) {
                    bottom:-4em;
                }
                background-color:var(--currentbgcolor);
                transition: background-color 2s; //match body bg color change speed
                filter:blur(20px);
            }
            .line {
                position: absolute;
                background-color: $lightblue;
                border-radius: 99px;

                @include breakpoint(medium) {
                    top:0;
                    height:100%;
                    width:2px;
                    left:-1px;
                }
                @include breakpoint(small down) {
                    left:0; //will be changed by JavaScript
                    width:45%; //will be changed by JavaScript
                    height:2px;
                    top:-1px;
                }
            }
            .text {
                position: relative;
                @include breakpoint(medium) {
                    padding:1em 1em 4em 1em;
                }
                @include breakpoint(small down) {
                    padding:2em 0 0 0;
                }
            }
        }
    }
    .tlitem.invertpopupside1 {
        .ctrinfo {
            @include breakpoint(medium) {
                right:0;
                left:initial;
            }
            .line {
                left:initial;
                right:-1px;
            }
        }
    }
    .tlitem {
        &.odd1 { //1,3,5
            @include breakpoint(medium) {
                .label, .ctrinfo {
                    bottom:rem-calc(24);
                }
            }
            @include breakpoint(small down) {
                .label {
                    right:rem-calc(24);
                }
            }
        }
        &.odd0 { //2,4,6 (=even)
            @include breakpoint(medium) {
                .label {
                    top:rem-calc(24);
                }
                .ctrinfo {
                    bottom:rem-calc(24);
                }
            }
            @include breakpoint(small down) {
                .label {
                    left:rem-calc(24);
                }
            }
        }
    }

    .tlitem {
        .dot, .closebutton, .ctrinfo {
            transition: opacity .2s;
        }
        &:not(.open) {
            .closebutton, .ctrinfo {
                opacity: 0;
                pointer-events: none;
            }
        }
        &.open {
            z-index:1;
            .dot {
                opacity: 0;
                pointer-events: none;
            }
            .label {
                background-color: transparent;
                color:currentColor;
            }
        }
    }
}

//hover effect
.bl-timeline {
    @include breakpoint(medium) {
        .tlitem:not(.open):hover {
            --labelscale:1.2;
            &.odd1 .label {
                --labelshift:-0.4em;
            }
            &.odd0 .label {
                --labelshift:0.4em;
            }
        }
    }
}

//typo
.bl-timeline {
    .tlitem {
        .label {
            font-size:rem-calc(14);
        }
        .text {
            font-size:rem-calc(16);
        }
    }
}

//Layout-Varianten
.bl-timeline.layout-baufortschritt {
    @include breakpoint(medium) {
        .ctrtimeline {
            padding-left: calc(2*var(--layoutgridcolwidth));
            padding-right: calc(2*var(--layoutgridcolwidth));
        }
        .tlspacer {
            //Spaeter, wenn viele Eintraege sind, wieder auf autosize wechseln.
            flex-grow:0;
            width:var(--layoutgridcolwidth);
        }
        .ctrbackground {
            background-image: url('/assets/app/images/timelinebg-desktop-baufortschritt.webp');
        }
    }
    @include breakpoint(small down) {
        .tlspacer {
            //Spaeter, wenn viele Eintraege sind, wieder auf autosize wechseln.
            flex-grow:0;
            height:rem-calc(60);
        }
        .ctrbackground {
            background-image: url('/assets/app/images/timelinebg-mobile-baufortschritt.webp');
        }
    }
}