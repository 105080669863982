.buttonbar {}

.button {
  display: block;
  width: fit-content;
  padding: 0.4em 2em;
  border-radius: 9999px; 
  background-color: $buttonbg;
  color:$buttonfg;
  
  cursor: pointer;
}
button.button {
  //remove default <button> styling
  border:none;
  outline: none;
}

.button {
  transition:padding .2s, margin .2s;
}
@mixin buttonhover {
  margin-left: -1em;
  margin-right: -1em;
  padding-left:3em;
  padding-right:3em;
}
.button:hover {
  @include buttonhover;
}

//typo
.button { 
  font:inherit; //Reset <button> default font
  @include typo-button;
}