//positioning
.bl-vereinteam {
    @include grid12lr;

    @include breakpoint(medium) {
        .ctrtitle {
            grid-column: 8 / span 6;
            grid-row:1 / span 2;
            align-self: center;
        }
        .ctrteamimg {
            grid-column: 1 / span 6;
            grid-row:1;
        }
        .ctrtext {
            grid-column: 4 / span 3;
            grid-row:2;
            margin-top:3em;
        }
    }
    @include breakpoint(small down) {
        .ctrtitle {
            grid-column: 2 / span 12;
        }
        .ctrteamimg {
            grid-column: 1 / span 14;
        }
        .ctrtext {
            grid-column: 2 / span 12;
        }
        .ctrteamimg, .ctrtext {
            margin-top: 0.75em;
        }
    }
}

//styling
.bl-vereinteam {
    .ctrteamimg {
        img {
            @include w100bl;
        }
    }
}

//typo
.bl-vereinteam {
    .ctrtext {
        @include underlinelist;
    }
}