html, body {
  overflow-x: clip;
  //overflow-x: hidden breaks sticky (Seite "Planung")
  //https://www.terluinwebdesign.nl/en/css/position-sticky-not-working-try-overflow-clip-not-overflow-hidden/
}

:root {
  --bodybgoverlay: transparent;
  --currentbgcolor: var(--defaultbodybg);
}

body {
  margin:0;
  padding:0;
  //background-color: var(--bodybg);
  //transition: background-color 1s, color .6s;
  transition:color .6s;
  background-color: var(--defaultbodybg);
}

#bgcoloroverlay {
  z-index:-5;
  background-color:var(--bodybgoverlay);
  position:fixed;
  left:0;
  right:0;
  top:0;
  height:100lvh; //iphone scroll bug (zeigt fuer kurze Zeit nicht alles farbig an beim Verschwinden der Adressleiste), wenn bottom:0
  transition: background-color 2s;
}

.ctrhmf { //contains header,main,footer
  //Footer always on bottom, even on small pages
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  header {
    flex:0 0;
  }
  main { 
    flex:1 0;
  }
  footer {
    flex:0 0;
  }
}
